.button-show-tutorial {
  position: absolute;
  top: 20px;
  left: 50%;
}
.button-show-labels {
  position: absolute;
  top: 20px;
  left: 60%;
}

.button-tutorial {
  position: absolute;
  font: 12px/20px 'Arial Unicode MS Bold', Arial, Helvetica, sans-serif;
  top: 20%;
  padding: 100px 1px 100px 1px;
  margin: 0px;
  color: rgba(220, 220, 220, 0.95);
  border: 1px solid rgba(240, 240, 240, 0.95);
  border-radius: 5px;
  background-color: rgba(3, 45, 73, 0.9);
  cursor: pointer;
}

.button-tutorial-next {
  right: 0.35%;
}

.button-tutorial-prev {
  left: 0.35%;
}

.tutorial-overlay {
  z-index: 2000;
  position: fixed;
  font: 14px 'Arial Unicode MS Bold', Arial, Helvetica, sans-serif;
  color: rgba(230, 230, 230, .95);
  border: rgba(255,255,255, 0.9) 3px solid;
  border-radius: 10px;
  background-color: rgba(99,102,106, 0.3);
  width: 95%;
  height: auto;
  top: 7%;
  left: 2.5%;
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(1.5px);
}
.button-close {
  position:absolute;
  color: rgba(255, 255, 255, 0.95);
  right: 10px;
  padding: 5px;
  margin: 10px;
  margin-top: 5px;
  border: 1px solid rgba(240, 240, 240, 0.95);
  border-radius: 5px;
  background-color: rgba(3, 45, 73, 0.9);
  cursor: pointer;
}

.tutorial-container {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 80px;
  gap: 20px;
  flex-direction: row;
}
.video-box {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid black;
  background-color: black;
  overflow: hidden;
  height: 300px;
  width: 300px;
  min-width: 300px;
}
.instruction-box {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  box-sizing: border-box;
  border: 2px solid black;
  background-color: rgba(3,43,73, 0.6);
  padding: 20px;
  padding-top: 30px;
  height: 300px;
  min-width: 360px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

@media (max-width : 800px) {
  .tutorial-container {flex-wrap: wrap;}
  .instruction-box  {width: 300px; min-width: 300px;}
  .video-box {width:300px;}
  .button-tutorial {
    padding: 200px 1px 200px 1px;
  }
}