.map-container {
  height: 95vh;
}

body {
  margin: 0;
  padding: 0;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.map-overlay.top {
  z-index: 1000;
}

.map-overlay {
  font: 12px/20px 'Arial Unicode MS Bold', Arial, Helvetica, sans-serif;
  position: absolute;
  width: 25%;
  top: 40px;
  left: 0;
  padding: 10px;
  margin-top: 15px;
}

.map-overlay .map-overlay-inner {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
}

.map-overlay input {
  background-color: transparent;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0;
  cursor: ew-resize;
}

.slider {
  -webkit-appearance: none;
  /*background-color: #ADD8E6;*/
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 20px;
  height: 8px;
  border: 1px solid;
}

.slider-color {
  /*background: linear-gradient(to right, #ADD8E6 40%, #00008B 60%);*/
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 13px;
  /* Set a specific slider handle width */
  height: 13px;
  /* Slider handle height */
  background: navy;
  /* Green background */
  margin-top: -13.5px;
  border-radius: 50%;
}

/* Define the CSS rule for the slider thumb when it has the 'iphone-slider' class */
.iphone-slider::-webkit-slider-thumb {
  margin-top: 0px;
}

.slider::-moz-range-thumb {
  width: 13px;
  /* Set a specific slider handle width */
  height: 13px;
  /* Slider handle height */
  background: blue;
  /* Green background */
  margin-top: -13.5px;
  border-radius: 50%;
}

/*jamming in the fixed tickmarks*/
.timeline-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.timeline-labels span {
  font-size: 12px;
}

#year {
  font-weight:'bold';
  text-align: center;
  font-size: 15px;
}

.navbar {
  /*background-color: black;*/
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  text-align: right;
  font-family: 'Arial Unicode MS Bold',
    monospace;
}

.navbar a {
  color: #000;
  padding-left: 20px
}

.high-labels {
  z-index: 1000;
}

.low-labels {
  z-index: 0;
}